//////////
// MASKS
//////////
(function($, APP) {
  APP.Plugins.Masks = {
    init: function() {
      $('[js-dateMask]').mask('99.99.99', { placeholder: 'ДД.ММ.ГГ' });
      $("input[type='tel']:not(.wfpay-input-text)").mask('+7 000 000 00 00#', {
        onChange: function(value, event, field) {
          var valueDigits = value.replace(/\D/g, '');
          if (valueDigits.length > 11 && value.indexOf('+7 8') == 0) {
            value = value.replace('+7 8', '+7 ');
            field.val(field.masked(value));
          }
        },
      });
    },
  };
})(jQuery, window.APP);
