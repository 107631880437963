//////////
// INPUT READER
//////////
(function($, APP) {
  APP.Plugins.InputReader = {
    init: function() {
      this.clickListeners();
    },
    clickListeners: function() {
      var _this = this;

      _document.on('change', '.js-file-upload input', function(e) {
        var parent = $(this).parent();
        _this.readURL(this);
      });

      _document.on('click', '.js-remove-file', function() {
        var $input = $(this)
          .closest('.ui-file')
          .find('input');
        $input.val('');
        $input.trigger('change');
      });
    },
    readURL: function(input) {
      var parent = $(input).parent();
      if (input.files && input.files[0]) {
        var file = input.files[0];
        var $stats = parent.find('.ui-file__upload-stats');

        // limits
        var sizeMb = file.size / 1024 / 1024;
        // handled by validations.js
        // if (FileSize > 5) {
        //   alert('Файл превышает 5 Мб');
        //   return;
        // }

        var size = sizeMb.toFixed(2) + ' Мб';
        var statsContent = `${file.name} (${size})`;
        $stats.html(statsContent);
        parent.addClass('has-file');

        // reader
        // var reader = new FileReader();

        // reader.onload = function(e) {
        //   var size = sizeMb.toFixed(2) + ' Мб';
        //   var statsContent = `${file.name} (${size})`;
        //   $stats.html(statsContent);
        //   parent.addClass('has-file');
        // };

        // reader.readAsDataURL(input.files[0]);

        // attach another if have file (5 max files)
        var lastAttrId = $('.js-file-upload')
          .last()
          .find('input')
          .attr('id');
        var nextId = parseInt(lastAttrId.split('_')[1]) + 1;

        if (nextId <= 5) {
          var id = `file_${nextId}`;

          var $newUploader = `
            <div class="ui-file js-file-upload">
              <div class="ui-file__upload">
                <div class="ui-file__upload-icon">
                  <svg class="ico ico-mono-attached-file">
                    <use xlink:href="#ico-mono-attached-file"></use>
                  </svg>
                </div>
                <div class="ui-file__upload-info">
                  <div class="ui-file__upload-stats"></div>
                  <div class="ui-file__upload-remove js-remove-file">удалить</div>
                </div>
              </div>
              <input type="file" name="file[]" id="${id}" accept="image/x-png,image/gif,image/jpeg,application/pdf">
              <label for="${id}"><span>Прикрепить файл</span></label>
            </div>
          `;

          $('.ui-files').append($newUploader);
        }
      } else {
        parent.removeClass('has-file');
        // prevent double non -has-file input
        $('.js-file-upload').each(function(i, upl) {
          var $upl = $(upl);

          if ($upl.is(parent)) return true;
          if ($upl.is(':not(.has-file)')) {
            $upl.remove();
          }
        });
      }

      // show/hide next
      // var $file1 = $('.ui-file[data-id="1"]');
      // var file1Val = $file1.find('input').val();
      // var $file2 = $('.ui-file[data-id="2"]');
      // var file2Val = $file2.find('input').val();

      // if (file1Val) {
      //   $file2.fadeIn();
      // } else {
      //   $file2.fadeOut();
      // }
    },
  };
})(jQuery, window.APP);
