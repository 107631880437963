(function() {
  if (!window.Sentry) return;

  if (window.sentry_dsn !== "") {
    Sentry.init({
      dsn: window.sentry_dsn,
      environment: window.sentry_environment,
      release: window.sentry_release,
    });
  }

  var testJSError = document.getElementById("test-js-error");
  if (testJSError) {
    testJSError.addEventListener("click", function (ev) {
      ev.preventDefault()
      throw new Error("Исключение JS вызванное вручную")
    })
  }
})();
